import React from "react";
import Layout from "../components/layout";
import UnoSection from "../components/uno-section";
import problemIntroImage from "../images/footer-pages/contact-intro-d.jpg";

const ContactPage = () => (
  <Layout currentPage="contact">
    <UnoSection
      backgroundImage={problemIntroImage}
      minHeight="50vh"
      verticalCenter
      style={{ paddingBottom: "120px" }}
    >
      <div className="thispagetitle">Contact</div>
      <p>
        110 Wall Street
        <br />
        New York, NY 10005
      </p>
      <p><a href="tel:1-646-916-5149" style={{ textDecoration: "none" }}>+1 (646) 916-5149</a></p>
    </UnoSection>
    <div className="container">
      <div
        className="row col-md-12"
        style={{ paddingBottom: "100px", paddingTop: "100px" }}
      >
        <div
          className="col-md-4 aligned-left "
          style={{ paddingBottom: "60px" }}
        >
          <strong>Partnerships</strong>
          <br />
          If you're a merchant or workplace looking to partner with Uno, please contact our {" "}
          <a href="mailto:bizdev@introducing.uno">Business Development team</a>.
        </div>

        <div
          className="col-md-4 aligned-left"
          style={{ paddingBottom: "60px" }}
        >
          <strong>Media</strong>
          <br />
          To help us spread the word about Uno, please contact our{" "}
  <a href="mailto:media@introducing.uno">Media team. </a>
        </div>

        <div
          className="col-md-4 aligned-left"
          style={{ paddingBottom: "60px" }}
        >
          <strong>General Inqueries</strong>
          <br />
          For anything else, please{" "}
          <a href="mailto:info@introducing.uno">email</a> our general inbox.
        </div>
      </div>
    </div>
  </Layout>
);
export default ContactPage;
